import { useState } from "react";
import toast from "react-hot-toast";

import Button from "../Common/Button";
import ConfirmPopup from "../Common/ConfirmPopup";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";
import CreateThemePopup from "./CreateThemePopup";
import EditThemePopup from "./EditThemePopup";

import CloseIcon from "../icons/CloseIcon";
import EditIcon from "../icons/EditIcon";
import PlusIcon from "../icons/PlusIcon";
import RemoveIcon from "../icons/RemoveIcon";
import ResetIcon from "../icons/ResetIcon";

import DEFAULT_THEME_COLORS from "../../const/theme";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteThemeAction,
  updateCurrentTheme,
} from "../../redux/slices/globalSlice";
import { applyTheme, applyThemeColors } from "../../utils/theme";

import { ITheme } from "../../interfaces/theme";

interface SelectThemePopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

const SelectThemePopup = ({
  isPopupOpen,
  closePopup,
}: SelectThemePopupProps) => {
  const dispatch = useAppDispatch();
  const { themes } = useAppSelector((state) => state.global);

  const [selectedTheme, setSelectedTheme] = useState(-1);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteThemeId, setDeleteThemeId] = useState(-1);
  const [editTheme, setEditTheme] = useState<ITheme>();

  const [isCreateThemePopupOpen, setIsCreateThemePopupOpen] = useState(false);
  const [isEditThemePopupOpen, setIsEditThemePopupOpen] = useState(false);

  const closeRemovePopup = () => {
    setShowDeleteConfirm(false);
  };

  const closeCreateThemePopup = () => {
    setIsCreateThemePopupOpen(false);
  };

  const closeEditThemePopup = () => {
    setIsEditThemePopupOpen(false);
  };

  const handleOnApply = () => {
    if (selectedTheme === -1) {
      dispatch(updateCurrentTheme("Default"));
    } else {
      if (selectedTheme >= 100) {
        applyThemeColors(themes[selectedTheme - 100].themeColors);
        dispatch(updateCurrentTheme(themes[selectedTheme - 100].themeName));
      } else {
        dispatch(
          updateCurrentTheme(Object.keys(DEFAULT_THEME_COLORS)[selectedTheme])
        );
      }
    }
    closePopup();
  };

  const handleOnCreateTheme = () => {
    setIsCreateThemePopupOpen(true);
    closePopup();
  };

  const handleOnDelete = () => {
    setShowDeleteConfirm(true);
  };

  const handleOnEdit = () => {
    setIsEditThemePopupOpen(true);
  };

  const onConfirmPopup = () => {
    toast.promise(
      dispatch(deleteThemeAction({ themeId: deleteThemeId })).unwrap(),
      {
        loading: "Deleting theme",
        success: () => {
          closeRemovePopup();
          return "Deleted custom theme";
        },
        error: "Error occurred during delete theme.",
      }
    );
  };

  return (
    <>
      <MobilePopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        backBg="bg-overlay"
        popupBg="bg-custom-deepCharcoal"
        popupBorder="ring-custom-custom-white-30"
      >
        <div className="flex flex-col gap-m">
          <div className="flex flex-col items-center justify-between pt-4 pb-6 px-4 gap-6">
            <div className="flex flex-col w-full gap-1">
              <div className="flex w-full justify-between items-center py-[5px]">
                <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
                  Select Theme
                </h2>

                <IconButton onClick={closePopup} padding={0}>
                  <CloseIcon className="text-primaryText" />
                </IconButton>
              </div>

              <h3 className="text-custom-white-b3 font-primary text-xs not-italic font-normal leading-[120%]">
                Customize background, by choosing a color template
              </h3>
            </div>

            <div className="flex w-full grid-cols-4 gap-3 items-top overflow-x-auto pb-2 pt-1">
              {Object.keys(DEFAULT_THEME_COLORS).map((themeName, index) => {
                const themeColors = DEFAULT_THEME_COLORS[themeName];

                return (
                  <div
                    className="flex flex-col gap-3 h-[100%] max-w-[80px] min-w-[80px] cursor-pointer"
                    key={index}
                  >
                    <div
                      className={`p-[10px] rounded-[8px] transition ${
                        selectedTheme === index
                          ? "border-[3px] border-solid border-primary"
                          : "border-[3px] border-solid border-transparent"
                      }`}
                      style={{ backgroundColor: themeColors[0] }} // Dynamically set the background color
                      onClick={() => {
                        setSelectedTheme(index);
                      }}
                    >
                      <div className="grid grid-cols-2">
                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: themeColors[3] }} // Dynamically set the background color
                        ></div>
                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: themeColors[4] }} // Dynamically set the background color
                        ></div>

                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: themeColors[5] }} // Dynamically set the background color
                        ></div>

                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: themeColors[6] }} // Dynamically set the background color
                        ></div>
                      </div>
                    </div>

                    <div className="text-custom-white-b3 px-3 text-center font-primary text-[12px] not-italic font-normal leading-[120%]">
                      {themeName}
                    </div>
                  </div>
                );
              })}

              {themes.map((theme, index) => {
                return (
                  <div
                    className="flex flex-col gap-3 h-[100%] max-w-[80px] min-w-[80px] cursor-pointer"
                    key={index}
                  >
                    <div
                      className={`p-[10px] rounded-[8px] transition relative ${
                        selectedTheme === index + 100
                          ? "border-[3px] border-solid border-primary"
                          : "border-[3px] border-solid border-transparent"
                      }`}
                      style={{ backgroundColor: theme.themeColors[0] }} // Dynamically set the background color
                      onClick={() => {
                        setSelectedTheme(index + 100);
                      }}
                    >
                      <div className="grid grid-cols-2">
                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: theme.themeColors[3] }} // Dynamically set the background color
                        ></div>
                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: theme.themeColors[4] }} // Dynamically set the background color
                        ></div>

                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: theme.themeColors[5] }} // Dynamically set the background color
                        ></div>

                        <div
                          className="w-[100%] h-[24px]"
                          style={{ backgroundColor: theme.themeColors[6] }} // Dynamically set the background color
                        ></div>
                      </div>

                      <div className="absolute right-[-8px] top-[-4px] ">
                        <IconButton
                          size={16}
                          onClick={() => {
                            setDeleteThemeId(theme.id);
                            handleOnDelete();
                          }}
                          className={
                            "bg-primary  hover:bg-primary active:bg-primary-300"
                          }
                        >
                          <RemoveIcon className="w-4 h-4 text-base" />
                        </IconButton>
                      </div>

                      <div className="absolute left-[-8px] top-[-4px] ">
                        <IconButton
                          size={16}
                          onClick={() => {
                            setEditTheme(theme);
                            handleOnEdit();
                          }}
                          className={
                            "bg-primary  hover:bg-primary active:bg-primary-300"
                          }
                        >
                          <EditIcon className="w-4 h-4 text-base" />
                        </IconButton>
                      </div>
                    </div>

                    <div className="text-custom-white-b3 px-3 text-center font-primary text-[12px] not-italic font-normal leading-[120%]">
                      {theme.themeName}
                    </div>
                  </div>
                );
              })}

              <div className="flex flex-col gap-3 h-[100%] max-w-[80px] min-w-[80px]">
                <div
                  className={`p-[10px] rounded-[8px] flex items-center justify-center cursor-pointer hover:bg-custom-darkTeal active:bg-custom-darkTeal transition ${"border-[3px] border-solid border-transparent"}`}
                  onClick={handleOnCreateTheme}
                >
                  <PlusIcon className="text-primaryText w-12 h-12" />
                </div>

                <div className="text-custom-white-b3 px-3 text-center font-primary text-[12px] not-italic font-normal leading-[120%]">
                  Custom
                </div>
              </div>
            </div>

            <div className="flex gap-4 w-full">
              <Button
                bgColor="bg-primary"
                activeColor="active:bg-neutral-200"
                className="py-sm px-m max-h-[40px] w-[100%] rounded-sm"
                onClick={handleOnApply}
              >
                <span className="font-primary text-base-highlight text-sm not-italic font-medium leading-[120%]">
                  Apply
                </span>
              </Button>

              <Button
                bgColor="bg-secondary"
                activeColor="active:bg-neutral-200"
                className="py-[6px] px-[10px] max-h-[40px]"
                onClick={() => {
                  setSelectedTheme(-1);
                  applyTheme("Default");
                }}
              >
                <span className="font-primary text-custom-buttonText text-sm not-italic font-medium leading-[120%]">
                  Reset
                </span>

                <ResetIcon className="text-custom-buttonText" />
              </Button>
            </div>
          </div>
        </div>
      </MobilePopup>

      <ConfirmPopup
        isOpen={showDeleteConfirm}
        onClose={closeRemovePopup}
        backBg="bg-overlay"
        onConfirm={onConfirmPopup}
        onCancel={closeRemovePopup}
      >
        <div className="rounded-sm">
          <div className="text-primaryText text-center font-primary text-xl not-italic font-semibold leading-[120%]">
            Delete this theme?
          </div>
          <div className="mt-[8px] text-primaryText-highlight text-center font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]"></div>
        </div>
      </ConfirmPopup>

      <CreateThemePopup
        isPopupOpen={isCreateThemePopupOpen}
        closePopup={closeCreateThemePopup}
      />

      <EditThemePopup
        isPopupOpen={isEditThemePopupOpen}
        closePopup={closeEditThemePopup}
        editTheme={editTheme}
      />
    </>
  );
};

export default SelectThemePopup;
