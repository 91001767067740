export interface IProfile {
  name: string;
  role?: string;
  photo?: string;
  salesRank: number;
  installsRank: number;
  cleanSalesRank: number;
}

export enum ProfileCategory {
  Rep = "Rep",
  Team = "Team",
  Region = "Region",
  Division = "Division",
  Channel = "Channel",
}
