import React, { useEffect, useState } from "react";

interface IconToggleProps {
  value: boolean;
  className?: string;
  onValueChange: () => void;
  iconOn: React.ReactNode;
  iconOff: React.ReactNode;
}

const IconToggle: React.FC<IconToggleProps> = ({
  value,
  className,
  onValueChange,
  iconOn,
  iconOff,
}) => {
  const [toggled, setToggled] = useState(value);

  const handleToggle = () => {
    setToggled(!toggled);
    onValueChange();
  };

  useEffect(() => {
    setToggled(value);
  }, [value]);

  return (
    <div
      className={`relative w-[24px] h-[24px] flex items-center justify-center cursor-pointer ${className}`}
      onClick={handleToggle}
    >
      {/* Toggled Icon */}
      <div
        className={`absolute  transform transition-all duration-500 ease-in-out ${
          toggled ? "opacity-100 scale-100" : "opacity-0 scale-50"
        }`}
      >
        {iconOn}
      </div>

      {/* Non-Toggled Icon */}
      <div
        className={`absolute  transform transition-all duration-500 ease-in-out ${
          toggled ? "opacity-0 scale-50" : "opacity-100 scale-100"
        }`}
      >
        {iconOff}
      </div>
    </div>
  );
};

export default IconToggle;
