import { useDrag } from "@use-gesture/react";
import isMobile from "is-mobile";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import NavBar from "../../components/NavBar";
import SideMenu from "../../components/SideMenu";
import SelectThemePopup from "../../components/Theme/SelectThemePopup";
import Header from "./Header";

import { PageType } from "../../const/enums/PageType";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { checkSession } from "../../redux/slices/authSlice";
import {
  fetchThemesAction,
  setCurrentPage,
  setMenuOpened,
} from "../../redux/slices/globalSlice";
import {
  fetchFavorites,
  fetchHomeCompetitionCardsAction,
  fetchHomeProfileCardsAction,
  fetchHomeSheetsAction,
  fetchReportList,
} from "../../redux/slices/reportSlice";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { authInfo } = useAppSelector((state) => state.auth);

  const {
    isMenuOpened,
    isPopupOpened,
    showLoadingOverlay,
    isFullScreen,
    currentPage,
    previousPage,
  } = useAppSelector((state) => state.global);

  const [isSelectThemeOpen, setIsSelectThemeOpen] = useState(false);
  const [headerHidden, setHeaderHidden] = useState(false);

  const openSelectTheme = () => {
    setIsSelectThemeOpen(true);
  };

  const closeSelectTheme = () => {
    setIsSelectThemeOpen(false);
  };

  const closeMenu = () => {
    dispatch(setMenuOpened(false));
    dispatch(setCurrentPage(previousPage));
  };

  const fetchInitialData = useCallback(() => {
    dispatch(checkSession())
      .unwrap()
      .then(() => {
        dispatch(fetchThemesAction());
        dispatch(fetchReportList());
        dispatch(fetchFavorites());
        dispatch(fetchHomeSheetsAction());
        dispatch(fetchHomeCompetitionCardsAction());
        dispatch(fetchHomeProfileCardsAction());
      })
      .catch(() => {
        navigate("/login");
      });
  }, [dispatch, navigate]);

  useEffect(() => {
    setHeaderHidden(location.pathname === "/login");
  }, [currentPage, location]);

  useEffect(() => {
    const button = document.getElementById("chatbase-bubble-button");
    if (button) {
      button.style.display = !isMenuOpened && !isPopupOpened ? "block" : "none"; // Hides the button
    }
  }, [isMenuOpened, isPopupOpened]);

  // Fetch the report list when the component mounts
  useEffect(() => {
    if (authInfo.userId === -1) return;
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  // Swipe gesture handler to open the menu
  const bind = useDrag(
    ({ swipe: [swipeX], last, movement: [mx], event }) => {
      const sliderElements = document.getElementsByClassName("slide-container");

      // Check if the drag started on the slider
      if (sliderElements.length > 0) {
        for (let i = 0; i < sliderElements.length; i++) {
          const sliderElement = sliderElements[i];
          // Cast state.event.target to Node
          const target = event.target as Node;

          if (sliderElement && sliderElement.contains(target)) {
            // Prevent the drag event if it originated from the slider
            event.preventDefault();
            return; // Exit the drag handling
          }
        }

        const sliderElement = sliderElements[0];
        // Cast state.event.target to Node
        const target = event.target as Node;

        if (sliderElement && sliderElement.contains(target)) {
          // Prevent the drag event if it originated from the slider
          event.preventDefault();
          return; // Exit the drag handling
        }
      }

      if (swipeX === 1 || (last && mx > (isMobile() ? 150 : 200))) {
        dispatch(setMenuOpened(true)); // Open the side menu on swipe right
        if (!isMenuOpened) {
          dispatch(setCurrentPage(PageType.SideMenu));
        }
      } else if (swipeX === -1 || (last && mx < -(isMobile() ? 150 : 200))) {
        dispatch(setMenuOpened(false)); // Close the side menu on swipe left
        dispatch(setCurrentPage(previousPage));
      }
    },
    {
      axis: "x", // Only detect horizontal swipes
      filterTaps: true,
    }
  );

  return (
    <div
      {...bind()}
      className="flex flex-col h-screen bg-opposite text-primaryText touch-none"
    >
      {!headerHidden && !isFullScreen && (
        <Header openThemeSelectPopup={openSelectTheme} />
      )}

      <main
        className={`flex-1 ${
          isFullScreen ? "" : "max-h-fit-height overflow-y-scroll"
        }`}
      >
        {children}
      </main>

      <SideMenu isMenuOpen={isMenuOpened} closeMenu={closeMenu} />

      <NavBar isMenuOpen={isMenuOpened} />

      <SelectThemePopup
        isPopupOpen={isSelectThemeOpen}
        closePopup={closeSelectTheme}
      />

      <Toaster />

      <Loading
        loading={showLoadingOverlay}
        background="#000000AA"
        loaderColor="#FFFFFF"
      />
    </div>
  );
};

export default MainLayout;
