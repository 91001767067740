import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  Bracket,
  IRenderSeedProps,
  IRoundProps,
  ISeedProps,
  SeedItem,
  SeedTeam,
} from "react-brackets";
import toast from "react-hot-toast";
import styled from "styled-components";

import IconButton from "../../../Common/IconButton";

import ArrowPageRight from "../../../icons/ArrowPageRight";
import InfoIcon from "../../../icons/InfoIcon";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";

import { IViperBracketData } from "../../../../interfaces/viperBracketData";
import { HomeCompetitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";

import { generateBracketRounds } from "../helper/bracket";

// Define the props for CustomSeed
interface CustomSeedProps extends IRenderSeedProps {
  brackets: IViperBracketData[];
  setBracketDetail: (bracket: IViperBracketData) => void;
  setSeedDetail: (seed: ISeedProps) => void;
}

const CustomSingleLineSeedWrapper = styled.div(
  ({ mobileBreakpoint }: { mobileBreakpoint: number }) => `
  padding: 1em 1.5em;
  min-width: 225px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;

  @media (max-width: 0px) {
    width: 100%;
  }

  @media (min-width: 1px) {
    &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 3em;

      [dir="rtl"] & {
        left: -1.5em;
      }
      [dir="twoSided"] & {
        left: -1.5em;
      }
      [dir="ltr"] & {
        right: -1.5em;
      }
    }

    &:nth-child(even)::after {
      border-bottom: 1px solid #8389a180 !important;
      top: -0.5px;
    }
    &:nth-child(odd)::after {
      border-top: 1px solid #8389a180 !important;
      top: calc(50% - 0.5px);
    }
  }
`
);

const CustomSeedWrapper = styled.div(
  ({ mobileBreakpoint }: { mobileBreakpoint: number }) => `
    padding: 1em 1.5em;
    min-width: 225px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;

    @media (max-width: 0px) {
        width: 100%;
    }

    @media (min-width: 1px) {
        &::after {
            content: "";
            position: absolute;
            height: 50%;
            width: 1.5em;

            [dir="rtl"] & {
                left: 0px;
            }
            [dir="ltr"] & {
                right: 0px;
            }
        }

        &:nth-child(even)::after {
            border-bottom: 1px solid #8389A180;
            top: -0.5px;

            [dir="rtl"] & {
                border-left: 1px solid #8389A180;
            }
            [dir="ltr"] & {
                border-right: 1px solid #8389A180;
            }
        }

        &:nth-child(odd):not(:last-child)::after {
            border-top: 1px solid #8389A180;
            top: calc(50% - 0.5px);

            [dir="rtl"] & {
                border-left: 1px solid #8389A180;
            }
            [dir="ltr"] & {
                border-right: 1px solid #8389A180;
            }
        }
    }
`
);

const CustomSeed = ({
  seed,
  rounds,
  breakpoint,
  roundIndex,
  seedIndex,
  brackets,
  setBracketDetail,
  setSeedDetail,
}: CustomSeedProps) => {
  const homeTeam = seed.teams[0];
  const awayTeam = seed.teams[1];

  const isLineConnector =
    rounds &&
    rounds[roundIndex].seeds.length === rounds[roundIndex + 1]?.seeds.length;

  const Wrapper = isLineConnector
    ? CustomSingleLineSeedWrapper
    : CustomSeedWrapper;

  return (
    <Wrapper mobileBreakpoint={0} className={"!py-[17px] !pl-[0px] mr-[18px]"}>
      <SeedItem className="flex !bg-transparent !shadow-none items-center gap-[6px] justify-between pr-[32px]">
        <div className="flex flex-col gap-[3px] w-full">
          <SeedTeam className="bg-viper-darkGrayishBlue !rounded-t-[6px] !p-[0]">
            <div className="select-none text-viper-softGrayishBlue rounded-t-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%]">
              {homeTeam.id}
            </div>

            <div className="select-none whitespace-nowrap overflow-hidden overflow-ellipse bg-viper-darkestGrayishBlue text-left px-4 w-full h-[38px] font-work text-[15px] not-italic font-medium leading-[150%] py-[7px]">
              {homeTeam.name ? homeTeam.name : "----"}
            </div>

            <div
              className={classNames(
                "text-softGrayishBlue rounded-t-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%] select-none",
                {
                  "bg-viper-primary": homeTeam.isWinner,
                  "bg-viper-darkGrayishBlue": !homeTeam.isWinner,
                }
              )}
            >
              {homeTeam.score}
            </div>
          </SeedTeam>

          <SeedTeam className="bg-viper-darkGrayishBlue !rounded-b-[6px] !p-[0]">
            <div className="select-none text-viper-softGrayishBlue rounded-b-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%]">
              {awayTeam.id}
            </div>

            <div className="select-none whitespace-nowrap overflow-hidden overflow-ellipse bg-viper-darkestGrayishBlue text-left px-4 w-full h-[38px] font-work text-[15px] not-italic font-medium leading-[150%] py-[7px]">
              {awayTeam.name ? awayTeam.name : "----"}
            </div>

            <div
              className={classNames(
                "text-softGrayishBlue rounded-b-[6px] flex items-center justify-center w-10 h-10 text-center font-work text-base not-italic font-medium leading-[150%] select-none",
                {
                  "bg-viper-primary": awayTeam.isWinner,
                  "bg-viper-darkGrayishBlue": !awayTeam.isWinner,
                }
              )}
            >
              {awayTeam.score}
            </div>
          </SeedTeam>
        </div>

        <div className="flex items-center gap-[6px] relative">
          <IconButton
            onClick={() => {
              const bracketId = seed.bracketId;
              const bracket = brackets.find(
                (bracket) => bracket.id === bracketId
              );

              if (bracket) {
                setBracketDetail(bracket);
                setSeedDetail(seed);
              }
            }}
          >
            <InfoIcon className="text-viper-softGrayishBlue" />
          </IconButton>
        </div>
      </SeedItem>
    </Wrapper>
  );
};

interface BracketsProps {
  isHome?: boolean;
  brackets: IViperBracketData[];
  setBracketDetail: (bracket: IViperBracketData) => void;
  setSeedDetail: (seed: ISeedProps) => void;
}

// Explicitly define return type for clarity
const ViperBrackets = ({
  isHome = false,
  brackets,
  setBracketDetail,
  setSeedDetail,
}: BracketsProps) => {
  const dispatch = useAppDispatch();

  const { currentDivision } = useAppSelector(
    (state) => state.viper
  );

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [rounds, setRounds] = useState<IRoundProps[]>([]);

  const handleSwipeChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setRounds(generateBracketRounds(brackets));
  }, [brackets]);

  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "Viper - Brackets - " + currentDivision,
        cardType: HomeCompetitionCardType.ViperBrackets,
        cardParams: JSON.stringify({
          division: currentDivision
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className="flex gap-2 flex-col w-full">
    {
    !isHome &&
      <div className="flex justify-end pr-4">
          <IconButton
            size={24} 
            padding={0}
            className={"hover:bg-transparent flex"}
            onClick={handleHomeCard}
          >
            <HomeEmptyIcon className="w-5 h-5 text-white" />
          </IconButton>
      </div>
    }
      <Bracket
        bracketClassName={`slide-container pl-8 w-full overflow-y-scroll ${!isHome ? "mt-10" : ""}`}
        roundClassName="h-full"
        rounds={rounds}
        renderSeedComponent={(seedProps: IRenderSeedProps) => (
          <CustomSeed
            {...seedProps}
            brackets={brackets}
            setBracketDetail={setBracketDetail}
            setSeedDetail={setSeedDetail}
          />
        )}
        roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
          return (
            <div className="flex justify-between gap-3">
              <div className="bg-viper-darkGrayishBlue rounded-[6px] py-2 w-full">
                <div className="select-none text-center font-work text-[15px] not-italic font-medium leading-[150%]">
                  {title}
                </div>

                <div className="select-none text-viper-softGrayishBlue text-center font-work text-[13px] not-italic font-normal leading-[150%] tracking-[0.65px]">
                  {rounds[roundIndex]["data"]}
                </div>
              </div>

              <div className="w-[69px] flex items-center justify-center">
                {roundIndex !== rounds.length - 1 && (
                  <div
                    className="w-10 h-10 flex items-center justify-center rounded-[50%] bg-viper-darkGrayishBlue cursor-pointer"
                    onClick={() => {
                      setTabIndex((tab) => tab + 1);
                    }}
                  >
                    <ArrowPageRight className="text-viper-softGrayishBlue w-4 h-4" />
                  </div>
                )}
              </div>
            </div>
          );
        }}
        swipeableProps={{
          height: "100%",
          enableMouseEvents: true,
          index: tabIndex,
          onChangeIndex: handleSwipeChange,
        }}
      />
    </div>
    
  );
};

export default ViperBrackets;
