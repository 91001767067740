import { HomeProfileCardType } from "../../const/enums/HomeProfileCardType";
import { ProfileCategory } from "../../interfaces/profile";
import HomeEmptyIcon from "../icons/HomeEmptyIcon";
import IconButton from "../Common/IconButton";

export default function PersonalStats({ stats, handleHomeCard }: { stats: any, handleHomeCard: (name: string, type: HomeProfileCardType, params: any) => void }) {
  const handleHomeCardClick = (category: string) => {
    const cardName = category + (Array.isArray(stats[category]) ? "s" : " Stats");
    handleHomeCard(cardName, HomeProfileCardType.PersonalStats, {
      category
    });
  }

  return stats && Object.keys(stats).length > 0 ? (
    <div className="px-3 py-3">
      {Object.values(ProfileCategory)
        .reverse()
        .map((category, index) =>
          stats[category] ? (
            <div className="mt-8" key={index}>
              <div className="flex justify-between pr-4">
                <div className="font-bold mb-4">
                  {category}
                  {Array.isArray(stats[category]) ? "s" : " Stats"}
                </div>
                
                <IconButton
                  size={24} 
                  padding={0}
                  className={"hover:bg-transparent flex"}
                  onClick={() => handleHomeCardClick(category)}
                >
                  <HomeEmptyIcon className="w-5 h-5 text-white" />
                </IconButton>
              </div>
              {Array.isArray(stats[category]) ? (
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-neutral-900">
                      <th className="text-neutral-100 text-sm font-normal text-left w-[40%] py-2 pl-2">
                        {category}
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Sales
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Installs
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Clean Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats[category].map((row: any, index: number) => (
                      <tr key={index} className="border-b border-[#0B0A0A]">
                        <td className="text-neutral-100 text-left w-[40%] py-3 pl-2">
                          {row.name}
                        </td>
                        <td className="text-neutral-100 text-center">
                          {row.fields.sales.value}
                        </td>
                        <td className="text-neutral-100 text-center">
                          {row.fields.installs.value}
                        </td>
                        <td className="text-center font-semibold pr-2">
                          {row.fields.clean_sales.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-neutral-900">
                      <th className="text-neutral-100 text-sm font-normal text-left w-[50%] py-2 pl-2">
                        Stat
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal">
                        Rank
                      </th>
                      <th className="text-neutral-100 text-center text-sm font-normal pr-2">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(stats[category].fields).map(
                      (row: any, index: number) => (
                        <tr key={index} className="border-b border-[#0B0A0A]">
                          <td className="text-neutral-100 text-left w-[50%] py-3 pl-2">
                            {row.label}
                          </td>
                          <td className="text-neutral-100 text-center">
                            {!!row.rank && (
                              <span className="px-1 text-xs rounded border border-[#655F5F]">
                                {row.rank}
                              </span>
                            )}
                          </td>
                          <td className="text-center font-semibold pr-2">
                            {row.value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )
        )}
    </div>
  ) : (
    <div className="px-3 py-3">
      <p className="text-center font-primary text-sm not-italic font-semibold leading-[150%] mt-8">
        No information is available.
      </p>
      <p className="text-center font-primary text-sm not-italic text-custom-white-80 font-normal leading-[150%] mt-1">
        Please select a different date range.
      </p>
    </div>
  );
}
