export enum HomeCompetitionCardType {
  ViperChampionshipFinal = "ViperChampionshipFinal",
  ViperMyPerformance = "ViperMyPerformance",
  ViperBrackets = "ViperBrackets",
  Viper7WeekSeries = "Viper7WeekSeries",
  TheCupMyMatchUp = "TheCupMyMatchUp",
  TheCupCompetingFor = "TheCupCompetingFor",
  TheCupOtherMatchUps = "TheCupOtherMatchUps",
  TheCupBrackets = "TheCupBrackets",
  TheCupSeeding = "TheCupSeeding",
  TheCupSeedingPuma = "TheCupSeedingPuma",
}

export enum SheetCategory {
  Competition = "Competition",
  Report = "Report",
  Profile = "Profile",
}
