// src/context/ActionContext.tsx
import React, { createContext, ReactNode, useContext } from "react";
import { CompetitionIndex } from "../const/competition";
import { FavoriteType } from "../const/enums/FavoriteType";
import { PageType } from "../const/enums/PageType";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setCurrentPage,
  setReportFilterOpened,
  setReportViewOpened,
} from "../redux/slices/globalSlice";
import {
  applyFilter,
  fetchAllFilterViewsAction,
  fetchFilteredReportFilter,
  fetchSelectedFilterViewAction,
  toggleFavorite,
  updateAppliedDateType,
  updateSelectedDateType,
  updateReportFilter,
} from "../redux/slices/reportSlice";

// Define the shape of the context state
interface ActionContextType {
  fetchSelectedFilterView: () => void;
  fetchAllFilterViews: () => void;
  onHandleApplyFilters: () => void;
  onHandleToggleFavorite: (callback: () => void) => void;
}

// Create the context
const ActionContext = createContext<ActionContextType | undefined>(undefined);

// Provider component
export const ReportActionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const { currentPage } = useAppSelector((state) => state.global);
  const {
    selectedDateType,
    selectedDateRange,
    currentReport,
    reportDataInfo,
    selectedFilters,
  } = useAppSelector((state) => state.report);

  const fetchSelectedFilterView = () => {
    if (!currentReport?.id) return;

    dispatch(fetchSelectedFilterViewAction(currentReport.id))
      .unwrap()
      .then(() => {
        dispatch(
          fetchFilteredReportFilter({
            reportName: reportDataInfo.name,
            filters: selectedFilters,
          })
        );
      })
      .catch(() => {});
  };

  const fetchAllFilterViews = () => {
    if (!currentReport?.id) return;

    dispatch(fetchAllFilterViewsAction(currentReport.id));
  };

  const onHandleApplyFilters = () => {
    if (!currentReport) return;

    dispatch(updateSelectedDateType(selectedDateType));
    dispatch(updateAppliedDateType(selectedDateType));

    dispatch(
      updateReportFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );

    dispatch(applyFilter());

    dispatch(setCurrentPage(PageType.Report));
    dispatch(setReportFilterOpened(false));
    dispatch(setReportViewOpened(false));
  };

  const onHandleToggleFavorite = (callback: () => void) => {
    let favoriteType;
    let favoriteId;

    if (currentPage === PageType.Report) {
      favoriteType = FavoriteType.Report;
      favoriteId = currentReport?.id || 0;
    } else if (
      currentPage === PageType.CompetitionTheCup ||
      currentPage === PageType.CompetitionViper
    ) {
      favoriteType = FavoriteType.Competition;
      favoriteId = CompetitionIndex[currentPage];
    } else if (currentPage === PageType.Profile) {
      favoriteType = FavoriteType.Profile;
      favoriteId = 0; // Make profile page as favorite
    } else {
      return;
    }

    dispatch(
      toggleFavorite({
        favoriteType,
        favoriteId,
        favoriteName: "",
        favoriteImageLink: "",
      })
    )
      .unwrap()
      .then(() => {
        callback();
      });
  };

  return (
    <ActionContext.Provider
      value={{
        fetchAllFilterViews,
        fetchSelectedFilterView,
        onHandleApplyFilters,
        onHandleToggleFavorite,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};

// Custom hook for using the context
export const useReportActionContext = (): ActionContextType => {
  const context = useContext(ActionContext);
  if (!context) {
    throw new Error(
      "useActionContext must be used within an ReportActionProvider"
    );
  }
  return context;
};
