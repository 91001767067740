import classNames from "classnames";
import { format, startOfYear } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import Loading from "react-fullscreen-loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import MainProfile from "../../components/Profile/MainProfile";
import PersonalStats from "../../components/Profile/PersonalStats";
import ProfileBreadcrumb from "../../components/Profile/ProfileBreadcrumb";
import ProfileSearchModal from "../../components/Profile/ProfileSearchModal";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import RepBoard from "../../components/Profile/RepBoard";

import ProfileFilter from "../../components/Profile/ProfileFilter";
import { PageType } from "../../const/enums/PageType";
import { HomeProfileCardType } from "../../const/enums/HomeProfileCardType";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setReportFilterOpened,
} from "../../redux/slices/globalSlice";
import {
  clearProfile,
  fetchProfileAction,
  fetchRankBoardAction,
  fetchStatsAction,
  updateProfileFilter,
} from "../../redux/slices/profileSlice";
import { saveHomeProfileCardsAction } from "../../redux/slices/reportSlice";

export default function ProfileStats() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isLoadingCurrentProfile,
    currentProfile,
    rankBoards,
    stats,
    isLoadingStats,
    isLoadingRankBoards,
  } = useAppSelector((state) => state.profile);

  const { selectedDateRange } = useAppSelector((state) => state.profile);

  const { isReportFilterOpened, currentPage } = useAppSelector(
    (state) => state.global
  );

  const [searchParams] = useSearchParams();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [modalCloseable, setModalCloseable] = useState(true);

  const dependencies = [
    searchParams.get("name"),
    searchParams.get("category"),
    searchParams.get("start_date"),
    searchParams.get("end_date"),
  ];

  const profileQuery = useMemo(
    () => ({
      name: searchParams.get("name"),
      category: searchParams.get("category") || "",
      start_date:
        searchParams.get("start_date") ||
        format(startOfYear(new Date()), "yyyy-MM-dd"),
      end_date:
        searchParams.get("end_date") || format(new Date(), "yyyy-MM-dd"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );

  const rankBoardQuery = useMemo(
    () => ({
      ...profileQuery,
      order_by: searchParams.get("order_by") ?? "sales",
      order_dir: searchParams.get("order_dir") ?? "asc",
    }),
    [profileQuery, searchParams]
  );

  useEffect(() => {
    if (!profileQuery.category || !profileQuery.name) {
      setOpenSearchModal(true);
      setModalCloseable(false);

      dispatch(clearProfile());
      return;
    }
    setModalCloseable(true);

    dispatch(fetchProfileAction(profileQuery));

    dispatch(
      fetchStatsAction({
        statsQuery: profileQuery,
        searchCategory: profileQuery.category,
      })
    );
  }, [dispatch, navigate, profileQuery]);

  useEffect(() => {
    if (!rankBoardQuery.category || !rankBoardQuery.name) {
      return;
    }

    dispatch(fetchRankBoardAction(rankBoardQuery));
  }, [dispatch, rankBoardQuery]);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.Profile));

    dispatch(
      updateProfileFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (currentPage === PageType.Profile) {
      dispatch(setReportFilterOpened(false));
    }
  }, [currentPage, dispatch]);

  const containerClasses = classNames(
    "flex flex-col pb-20 gap-[18px] max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative"
  );

  const handleHomeCard = (card_name: string, card_type: HomeProfileCardType, params: any) => {
    toast.promise(
      dispatch(saveHomeProfileCardsAction({
        cardName: card_name + " - " + currentProfile?.name,
        cardType: card_type,
        cardParams: JSON.stringify({
          ...params,
          profileQuery,
          rankBoardQuery
        })
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={containerClasses}>
      <ProfileBreadcrumb
        onToggleNav={() => setOpenSearchModal(true)}
        category={profileQuery.category}
        profileName={profileQuery.name || "..."}
      />

      {isLoadingRankBoards || isLoadingCurrentProfile || isLoadingStats ? (
        <Loading loading={true} background="#000000AA" loaderColor="#FFFFFF" />
      ) : (
        currentProfile && (
          <>
            <MainProfile
              profile={currentProfile}
              category={profileQuery.category}
            />
            <ProfileTabs currentTab={currentTab} onSwitchTab={setCurrentTab} />
            {currentTab === 0 ? (
              <PersonalStats stats={stats} handleHomeCard={handleHomeCard}/>
            ) : (
              <RepBoard
                mainProfile={stats?.[profileQuery.category]}
                board={rankBoards ?? []}
                orderBy={rankBoardQuery.order_by}
                orderDir={rankBoardQuery.order_dir}
                handleHomeCard={handleHomeCard}
              />
            )}
          </>
        )
      )}

      <ProfileSearchModal
        open={openSearchModal}
        closeable={modalCloseable}
        onClose={() => setOpenSearchModal(false)}
      />

      <ProfileFilter isPopupOpen={isReportFilterOpened} />
    </div>
  );
}
