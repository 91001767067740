import classNames from "classnames";
import { useEffect } from "react";
import Report from "../../components/Report";
import { useScreenActions } from "../../hooks/useReportActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCurrentPage } from "../../redux/slices/globalSlice";
import { PageType } from "../../const/enums/PageType";

const ReportPage = () => {
  const dispatch = useAppDispatch();
  const { isPopupOpened } = useAppSelector((state) => state.global);

  // Use screen actions hook
  const {
    isFullScreen,
    fitOption,
    viewType,
    scale,
    toggleFullScreen,
    toggleViewType,
    handleFitOption,
    increaseScale,
    decreaseScale,
    reportRefs,
  } = useScreenActions();

  const containerClasses = classNames(
    "flex flex-col gap-[18px] p-[12px] max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative",
    {
      "overflow-hidden": true,
      "overflow-y-auto": !isPopupOpened,
      "max-h-fit-height": !isFullScreen.some((value) => value === true),
      "h-[100%] z-40": isFullScreen.some((value) => value === true), // Check if any is true
    }
  );

  useEffect(() => {
    dispatch(setCurrentPage(PageType.Report));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={containerClasses}>
      <Report
        scale={scale}
        viewType={viewType}
        fitOption={fitOption}
        isFullScreen={isFullScreen}
        toggleViewType={toggleViewType}
        toggleFullScreen={toggleFullScreen}
        handleFitOption={handleFitOption}
        increaseScale={increaseScale}
        decreaseScale={decreaseScale}
        reportRefs={reportRefs}
      />
    </div>
  );
};

export default ReportPage;
