import { useMemo } from "react";
import { FavoriteType } from "../const/enums/FavoriteType";
import { useAppSelector } from "../redux/hooks";
import { ProfileCategory } from "../interfaces/profile";

const useParsedFavorites = () => {
  const { favorites, reports, featuredCompetitions } = useAppSelector(
    (state) => state.report
  );

  return useMemo(() => {
    const flattenedReports = reports.flatMap((category) => category.reports);
    const favoritesInfo: any[] = [];

    favorites.forEach((favorite) => {
      if (favorite.favoriteType === FavoriteType.Report) {
        const foundReport = flattenedReports.find(
          (report) => report.id === favorite.favoriteId
        );
        if (foundReport) {
          favoritesInfo.push({
            ...foundReport,
            ...favorite,
            name: foundReport.reportName,
          });
        }
      } else if (favorite.favoriteType === FavoriteType.Competition) {
        const foundCompetition = featuredCompetitions[favorite.favoriteId];
        if (foundCompetition) {
          favoritesInfo.push({
            ...foundCompetition,
            ...favorite,
            name: foundCompetition.name,
          });
        }
      } else if (favorite.favoriteType === FavoriteType.Profile) {
        if (favorite.favoriteId === 1) {
          const profileName = favorite.favoriteName.split("-")[1].trim();
          const profileCategory = favorite.favoriteName.split("-")[0].trim();

          if (favorite.favoriteImageLink === "") {
            if (profileCategory === ProfileCategory.Rep) {
              favoritesInfo.push({
                ...favorite,
                imageUrl: "/assets/imgs/user.png",
              });
            } else {
              favoritesInfo.push({
                ...favorite,
                imageUrl: "/assets/imgs/users.png",
                name: profileName,
              });
            }
          } else {
            favoritesInfo.push({
              ...favorite,
              imageUrl: favorite.favoriteImageLink,
              name: profileName,
            });
          }
        } else {
          favoritesInfo.push({
            ...favorite,
            imageUrl: "/assets/imgs/user.png",
            name: "Profile",
          });
        }
      }
    });

    return favoritesInfo.sort((f1, f2) => f1.position - f2.position);
  }, [favorites, featuredCompetitions, reports]);
};

export default useParsedFavorites;
