import classNames from "classnames";
import { useEffect, useMemo } from "react";
import toast from "react-hot-toast";

import {
  ViperDivisionList,
  ViperMatchDataFieldTypes,
} from "../../../../data/Viper";

import IconButton from "../../../Common/IconButton";
import ViperBattleIcon from "../../../icons/ViperBattleIcon";
import HomeEmptyIcon from "../../../icons/HomeEmptyIcon";

import { HomeCompetitionCardType } from "../../../../const/enums/HomeCompetitionCardType";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getBracketDataAction } from "../../../../redux/slices/viperSlice";
import { saveHomeCompetitionCardsAction } from "../../../../redux/slices/reportSlice";

import { generateMatchDetail } from "../helper/bracket";

interface ChampionShipFinalProps {
  isHome?: boolean;
}

const ChampionShipFinal = ({ isHome = false }: ChampionShipFinalProps) => {
  const dispatch = useAppDispatch();

  const { viperBracketData } = useAppSelector((state) => state.viper);

  useEffect(() => {
    dispatch(
      getBracketDataAction({
        division: ViperDivisionList[1],
      })
    );
  }, [dispatch]);

  const parsedData = useMemo(() => {
    if (viperBracketData.length === 0) return [];

    const finalBracket = viperBracketData.find(
      (bracket) =>
        bracket.matchId === 10 && bracket.matchName === "Championship"
    );

    if (!finalBracket) return [];

    return Object.entries(generateMatchDetail(finalBracket)).map(
      ([key, values]) => {
        switch (ViperMatchDataFieldTypes[key]) {
          case "Dollar":
            return [`$${values[0]}`, `$${values[1]}`];
          case "Normal":
            return values;
          case "Percentage":
            return [`${values[0]}%`, `${values[1]}%`];

          default:
            return values;
        }
      }
    );
  }, [viperBracketData]);

  const finalBracket = viperBracketData.find(
    (bracket) => bracket.matchId === 10 && bracket.matchName === "Championship"
  );

  const handleHomeCard = () => {
    toast.promise(
      dispatch(saveHomeCompetitionCardsAction({
        cardName: "Viper - Championship Finals",
        cardType: HomeCompetitionCardType.ViperChampionshipFinal,
        cardParams: "{}"
      })).unwrap(),
      {
        loading: "Adding card to home screen",
        success: () => {
          return `Added card to home screen.`;
        },
        error: "The card is already added to home screen.",
      }
    );
  }

  return (
    <div className={`flex flex-col gap-4 px-6 ${!isHome ? "mt-10": "w-full"}`}>
      <div className="flex gap-2 items-center">
        <div className="bg-viper-primary w-[2px] h-[18px] rounded-[2px]"></div>
        <div className="flex justify-between flex-1">
          <div className="flex items-end gap-2">
            {!isHome && <div className="text-white font-work text-base not-italic font-medium leading-[150%]">
              Championship Finals
            </div> }

            <div className="text-viper-softGrayishBlue font-work text-xs not-italic font-medium leading-[150%] pb-[2px]">
              June 15th to June 20th
            </div>
          </div>
          {
          !isHome &&
            <IconButton 
              size={24}
              padding={0}
              className={"hover:bg-transparent flex"}
              onClick={handleHomeCard}
            >
              <HomeEmptyIcon className="w-5 h-5 text-white" />
            </IconButton>
          }
        </div>
      </div>
      {finalBracket && (
        <div className="flex flex-col gap-sm">
          <div className="flex items-center bg-viper-mediumDarkGrayishBlue rounded-[3px] p-sm">
            <div className="text-white font-work text-sm not-italic font-medium leading-[150%] flex-1 text-center">
              {finalBracket.teamName}
            </div>
            <ViperBattleIcon className="text-white" />
            <div className="text-white font-work text-sm not-italic font-medium leading-[150%] flex-1 text-center">
              {finalBracket.opponentTeamName}
            </div>
          </div>

          <div className="flex flex-col border-solid border-[1px] border-viper-mediumDarkGrayishBlue">
            {Object.entries(generateMatchDetail(finalBracket)).map(
              ([key, values], index) => (
                <div
                  key={key}
                  className={classNames("grid grid-cols-3", {
                    "bg-viper-darkGrayishBlue": index === 0,
                    "border-solid border-t-[1px] border-viper-mediumDarkGrayishBlue":
                      index !== 0,
                  })}
                >
                  <div className={"font-bold text-center p-[11px] m-auto"}>
                    <div
                      className={classNames(
                        "font-work text-sm not-italic font-medium leading-[150%]",
                        {
                          "flex items-center justify-center w-6 h-6":
                            index === 0,
                          "bg-viper-secondary rounded-[50%] text-viper-darkGrayishBlue":
                            index === 0 && values[0] > values[1],
                          "bg-viper-mediumDarkGrayishBlue rounded-[50%] text-white":
                            index === 0 && values[0] < values[1],

                          "text-viper-secondary":
                            values[0] > values[1] && index > 0,
                        }
                      )}
                    >
                      {parsedData[index][0]}
                    </div>
                  </div>

                  <div
                    className={classNames(
                      "font-work text-sm not-italic font-medium leading-[150%]",
                      "text-viper-softGrayishBlue text-center p-[11px] ",
                      "border-solid border-x-[1px] border-viper-mediumDarkGrayishBlue bg-viper-darkGrayishBlue"
                    )}
                  >
                    {key}
                  </div>

                  <div className="text-white text-center p-[11px] m-auto">
                    <div
                      className={classNames(
                        "font-work text-sm not-italic font-medium leading-[150%]",
                        {
                          "flex items-center justify-center w-6 h-6":
                            index === 0,
                          "bg-viper-secondary rounded-[50%] text-viper-darkGrayishBlue":
                            index === 0 && values[0] < values[1],
                          "bg-viper-mediumDarkGrayishBlue rounded-[50%] text-white":
                            index === 0 && values[0] > values[1],

                          "text-viper-secondary":
                            values[0] < values[1] && index > 0,
                        }
                      )}
                    >
                      {parsedData[index][1]}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChampionShipFinal;
