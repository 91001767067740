import { useEffect } from "react";
import { UserGroupIcon } from "@heroicons/react/16/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchHomeCardRankBoardAction,
  fetchHomeCardStatsAction,
} from "../../redux/slices/profileSlice";

interface Props {
  cardId: string;
  category: string;
  profileQuery: any;
  rankBoardQuery: any;
  orderBy: string;
  orderDir: string;
}

export default function RepBoard({
  cardId,
  category,
  profileQuery,
  rankBoardQuery,
  orderBy,
  orderDir,
}: Props) {
  const dispatch = useAppDispatch();
  const {
    homeStats: { [cardId]: stats },
    homeRankBoards: { [cardId]: board },
  } = useAppSelector((state) => state.profile);

  useEffect(() => {
    dispatch(
      fetchHomeCardStatsAction({
        cardId,
        statsQuery: profileQuery,
        searchCategory: profileQuery.category,
      })
    );
    dispatch(fetchHomeCardRankBoardAction({ cardId, rankBoardQuery }));
  }, [cardId, dispatch, profileQuery, rankBoardQuery]);

  if (!stats || !board) {
    return <></>;
  }

  const { [profileQuery.category]: mainProfile } = stats;
  const active = board.findIndex((m) => m.entityValue === mainProfile?.name);

  return mainProfile && board ? (
    <div className="w-full">
      <table className="w-full">
        <thead className="">
          <tr className="border-b border-neutral-800">
            <th className="text-neutral-100 text-center text-xs font-normal py-2 px-1">
              RK
            </th>
            <th className="text-left text-xs font-normal px-1">
              {mainProfile.category}
            </th>
            <th className="text-left text-xs font-normal px-1 w-12">
              <div className="flex items-center gap-1">
                <span>Sales</span>
                <SortIcon active={orderBy === "sales"} dir={orderDir} />
              </div>
            </th>
            <th className="text-xs font-normal px-1 w-12">
              <div className="flex items-center gap-1">
                <span>Installs</span>
                <SortIcon active={orderBy === "installs"} dir={orderDir} />
              </div>
            </th>
            <th className="text-xs font-normal px-1 w-16">
              <div className="flex items-center gap-1">
                <span>Clean Sales</span>
                <SortIcon active={orderBy === "clean_sales"} dir={orderDir} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {board.map((row, index) => (
            <tr key={row.entityValue} className="text-sm">
              <td className="p-0 text-center">
                <div
                  className={`px-1 py-3 rounded-l border-l-4 overflow-hidden ${
                    index === active
                      ? "bg-neutral-900 border-primary"
                      : "border-transparent"
                  }`}
                >
                  <span
                    className={`px-1 text-xs rounded ${
                      index === active
                        ? "text-neutral-950 bg-white"
                        : "border border-[#655F5F]"
                    }`}
                  >
                    {row[`${orderBy}_rank`]}
                  </span>
                </div>
              </td>
              <td
                className={`px-1 ${active === index ? "bg-neutral-900" : ""}`}
              >
                <div className="flex gap-2 items-center">
                  {row.photo ? (
                    <img
                      src={row.photo}
                      className="rounded-full size-6 border border-neutral-900"
                      alt=""
                    />
                  ) : (
                    <UserGroupIcon className="size-6" />
                  )}
                  <span className={active === index ? "font-semibold" : ""}>
                    {row.entityValue}
                  </span>
                </div>
              </td>
              <td
                className={`pr-3 text-center ${
                  active === index ? "bg-neutral-900" : ""
                }`}
              >
                {row.sales}
              </td>
              <td
                className={`pr-3 text-center ${
                  active === index ? "bg-neutral-900" : ""
                }`}
              >
                {row.installs}
              </td>
              <td
                className={`pr-1 text-center rounded-r ${
                  active === index ? "bg-neutral-900" : ""
                }`}
              >
                {row.cleanSales}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="px-3 py-3">
      <p className="text-center font-primary text-sm not-italic font-semibold leading-[150%] mt-8">
        No information is available.
      </p>
      <p className="text-center font-primary text-sm not-italic text-custom-white-80 font-normal leading-[150%] mt-1">
        Please select a different date range.
      </p>
    </div>
  );
}

interface SortIconProps {
  dir: string;
  active: boolean;
}

const SortIcon = ({ dir, active }: SortIconProps) =>
  active ? (
    dir === "asc" ? (
      <ArrowUpIcon className="size-3" />
    ) : (
      <ArrowDownIcon className="size-3" />
    )
  ) : (
    <></>
  );
