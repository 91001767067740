import { SelectedDateType } from "../const/enums/SelectedDateType";
import { IDateRangeType } from "../interfaces/dateRangeType";
import {
  getFullSeason,
  getLastMonth,
  getLastWeek,
  getMTD,
  getToday,
  getWTD,
  getYesterday,
  getYTD,
} from "../utils/date";

export const dateRanges: IDateRangeType[] = [
  { name: SelectedDateType.Today, range: getToday() },
  { name: SelectedDateType.Yesterday, range: getYesterday() },
  { name: SelectedDateType.FullSeason, range: getFullSeason() },
  { name: SelectedDateType.YTD, range: getYTD() },
  { name: SelectedDateType.LastWeek, range: getLastWeek() },
  { name: SelectedDateType.WTD, range: getWTD() },
  { name: SelectedDateType.LastMonth, range: getLastMonth() },
  { name: SelectedDateType.MTD, range: getMTD() },
];
