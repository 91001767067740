import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProfile,
  fetchRankBoard,
  searchProfile,
} from "../../services/profileService";
import { fetchStats } from "./../../services/profileService";
import { mapProfile } from "./../helpers/profile";

import { SelectedDateType } from "../../const/enums/SelectedDateType";
import { TimeUnit } from "../../const/enums/TimeUnit";
import { IProfile } from "../../interfaces/profile";
import { IProfileRankData } from "../../interfaces/profileRankBoard";
import { ISavedFilter } from "../../interfaces/savedFilter";
import { formatDate, getLastWeek } from "../../utils/date";
import { mapProfileRankBoard, parseStats } from "../helpers/profile";
import { IProfileSearchData } from "./../../interfaces/profileSearchData";

export interface ProfileState {
  isSearchingProfile: boolean;
  searchedProfiles: IProfileSearchData[];

  currentProfile: IProfile | null;
  isLoadingCurrentProfile: boolean;

  rankBoards: IProfileRankData[];
  isLoadingRankBoards: boolean;

  appliedFilters: ISavedFilter;
  selectedFilters: ISavedFilter;

  selectedDateRange: [string, string];
  selectedDateType: SelectedDateType;
  appliedDateType: SelectedDateType;
  selectedDateUnit: TimeUnit;

  stats: {
    [key: string]: any;
  };
  homeStats: {
    [key: string]: {
      [key: string]: any;
    }
  };
  homeRankBoards: {
    [key: string]: IProfileRankData[];
  };
  isLoadingStats: boolean;
}

const initialState: ProfileState = {
  isSearchingProfile: false,
  searchedProfiles: [],
  currentProfile: null,
  isLoadingCurrentProfile: false,

  rankBoards: [],
  isLoadingRankBoards: false,

  appliedFilters: {},
  selectedFilters: {},

  selectedDateRange: [
    formatDate(getLastWeek().from),
    formatDate(getLastWeek().to),
  ],

  selectedDateType: SelectedDateType.LastWeek,
  appliedDateType: SelectedDateType.LastWeek,
  selectedDateUnit: TimeUnit.Days,

  stats: {},
  homeStats: {},
  homeRankBoards: {},

  isLoadingStats: false,
};

export const searchProfileAction = createAsyncThunk(
  "profile/searchProfileAction",
  async ({ category, search }: { category: string; search: string }) => {
    const response = await searchProfile(category, search);
    return response;
  }
);

export const fetchProfileAction = createAsyncThunk(
  "profile/fetchProfileAction",
  async (profileQuery: any) => {
    const response = await fetchProfile(profileQuery);
    return response;
  }
);

export const fetchRankBoardAction = createAsyncThunk(
  "profile/fetchRankBoardAction",
  async (rankBoardQuery: any) => {
    const response = await fetchRankBoard(rankBoardQuery);
    return response;
  }
);

export const fetchStatsAction = createAsyncThunk(
  "profile/fetchStatsAction",
  async ({
    statsQuery,
    searchCategory,
  }: {
    statsQuery: any;
    searchCategory: string;
  }) => {
    const response = await fetchStats(statsQuery);
    return response;
  }
);

export const fetchHomeCardStatsAction = createAsyncThunk(
  "profile/fetchHomeCardStatsAction",
  async ({
    cardId,
    statsQuery,
    searchCategory,
  }: {
    cardId: string;
    statsQuery: any;
    searchCategory: string;
  }) => {
    const response = await fetchStats(statsQuery);
    return response;
  }
);

export const fetchHomeCardRankBoardAction = createAsyncThunk(
  "profile/fetchHomeCardRankBoardAction",
  async ({
    cardId,
    rankBoardQuery
  }: {
    cardId: string,
    rankBoardQuery: any
  }) => {
    const response = await fetchRankBoard(rankBoardQuery);
    return response;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    updateSelectedDateRange: (state, { payload }) => {
      state.selectedDateRange = payload;
    },

    updateSelectedDateType: (state, { payload }) => {
      state.selectedDateType = payload;
    },

    updateAppliedDateType: (state, { payload }) => {
      state.appliedDateType = payload;
    },

    applyFilter: (state) => {
      state.appliedFilters = state.selectedFilters;
    },

    clearFilter: (state) => {
      state.selectedFilters = {
        Date: state.selectedFilters["Date"],
      };
      state.selectedDateType = state.appliedDateType;
    },

    updateProfileFilter: (
      state,
      action: PayloadAction<{
        filterName: string;
        selectedOption: string | string[];
      }>
    ) => {
      const { filterName, selectedOption } = action.payload;
      state.selectedFilters[filterName] = selectedOption;
    },

    clearProfile: (state) => {
      state.currentProfile = null;
    },
    clearProfileFilters: (state) => {
      state.selectedDateRange = [
        formatDate(getLastWeek().from),
        formatDate(getLastWeek().to),
      ];
      state.selectedDateType = SelectedDateType.LastWeek;
      state.selectedFilters = {
        Date: [formatDate(getLastWeek().from), formatDate(getLastWeek().to)],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchProfileAction.pending, (state) => {
      state.isSearchingProfile = true;
    });
    builder.addCase(searchProfileAction.fulfilled, (state, { payload }) => {
      state.searchedProfiles = payload;
      state.isSearchingProfile = false;
    });
    builder.addCase(searchProfileAction.rejected, (state, { error }) => {
      state.isSearchingProfile = false;
    });

    builder.addCase(fetchProfileAction.pending, (state) => {
      state.isLoadingCurrentProfile = true;
    });
    builder.addCase(fetchProfileAction.fulfilled, (state, { payload }) => {
      state.currentProfile = mapProfile(payload);
      state.isLoadingCurrentProfile = false;
    });
    builder.addCase(fetchProfileAction.rejected, (state, { error }) => {
      state.isLoadingCurrentProfile = false;
    });

    builder.addCase(fetchRankBoardAction.pending, (state) => {
      state.isLoadingRankBoards = true;
    });
    builder.addCase(fetchRankBoardAction.fulfilled, (state, { payload }) => {
      state.rankBoards = payload.map((rank: any) => mapProfileRankBoard(rank));
      state.isLoadingRankBoards = false;
    });
    builder.addCase(fetchRankBoardAction.rejected, (state, { error }) => {
      state.isLoadingRankBoards = false;
    });
    
    builder.addCase(fetchHomeCardRankBoardAction.pending, (state) => {
      state.isLoadingRankBoards = true;
    });
    builder.addCase(fetchHomeCardRankBoardAction.fulfilled, (
      state,
      {
        payload,
        meta: {
          arg: { cardId },
        },
      }
    ) => {
      state.homeRankBoards[cardId] = payload.map((rank: any) => mapProfileRankBoard(rank));
      state.isLoadingRankBoards = false;
    });
    builder.addCase(fetchHomeCardRankBoardAction.rejected, (state, { error }) => {
      state.isLoadingRankBoards = false;
    });

    builder.addCase(fetchHomeCardStatsAction.pending, (state) => {
      state.isLoadingStats = true;
    });

    builder.addCase(
      fetchHomeCardStatsAction.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { searchCategory, cardId },
          },
        }
      ) => {
        state.homeStats[cardId] = parseStats(payload, searchCategory);
        state.isLoadingStats = false;
      }
    );

    builder.addCase(fetchHomeCardStatsAction.rejected, (state, { error }) => {
      state.isLoadingStats = false;
    });

    builder.addCase(fetchStatsAction.pending, (state) => {
      state.isLoadingStats = true;
    });
    builder.addCase(
      fetchStatsAction.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { searchCategory },
          },
        }
      ) => {
        state.stats = parseStats(payload, searchCategory);
        state.isLoadingStats = false;
      }
    );
    builder.addCase(fetchStatsAction.rejected, (state, { error }) => {
      state.isLoadingStats = false;
    });
  },
});

export const {
  updateSelectedDateRange,
  updateSelectedDateType,
  updateAppliedDateType,
  applyFilter,
  clearFilter,
  updateProfileFilter,
  clearProfile,
  clearProfileFilters,
} = profileSlice.actions;

export default profileSlice.reducer;
