import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DateDirection } from "../../const/enums/DateDirection";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { SelectedDateType } from "../../const/enums/SelectedDateType";
import { IDateRange } from "../../interfaces/dateRange";
import { IReport } from "../../interfaces/report";

import Button from "../Common/Button";
import DropDown from "../Common/DropDown";
import IconButton from "../Common/IconButton";
import SelectThemeButton from "../Theme/SelectThemeButton";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  applyFilter,
  updateSelectedDateType,
  updateReportFilter,
  updateSelectedDateRange,
} from "../../redux/slices/reportSlice";
import { getQueryParam } from "../../utils";
import { formatDateStandard } from "../../utils/date";
import { getShiftedDate } from "./helpers/date";
import { getFilterSummary } from "./helpers/filter";

import ArrowLeftIcon from "../icons/ArrowLeft";
import ArrowRightIcon from "../icons/ArrowRIght";
import EyeIcon from "../icons/EyeIcon";
import FilterIcon from "../icons/FilterIcon";
import VideoTutorialIcon from "../icons/VideoTutorialIcon";

interface ReportControlsProps {
  openVideo: () => void;
  openSelectTheme: () => void;
  openReportView: () => void;
  openTableauFilter: () => void;
  openReportFilter: (category: ReportFilterCategory) => void;
  selectedReport: IReport | null;
}

const ReportControls = ({
  openVideo,
  openSelectTheme,
  openReportFilter,
  openReportView,
  openTableauFilter,
  selectedReport,
}: ReportControlsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    appliedFilters,
    reportDataInfo,
    appliedDateType,
    currentReport,
    reportFilterViews,
  } = useAppSelector((state) => state.report);

  const [reportView, setReportView] = useState<string>("");

  const handleReportViewChange = (reportView: string) => {
    setReportView(reportView as string);

    navigate(`/report?alias=${currentReport?.alias}&view=${reportView}`);
  };

  const handleShiftDate = (dateDirection: DateDirection) => {
    const dateFilter = appliedFilters["Date"];
    if (!dateFilter) return;
    if (!currentReport?.reportName) return;

    const dateFilterRange: IDateRange = {
      from: new Date(dateFilter[0]),
      to: new Date(dateFilter[1]),
    };

    const shiftedDateFilterRange = getShiftedDate(
      dateFilterRange,
      appliedDateType,
      dateDirection
    );

    const newDateRange = [
      formatDateStandard(shiftedDateFilterRange.from),
      formatDateStandard(shiftedDateFilterRange.to),
    ];

    dispatch(updateSelectedDateType(SelectedDateType.Custom));
    dispatch(updateSelectedDateRange(newDateRange));
    dispatch(
      updateReportFilter({
        filterName: "Date",
        selectedOption: newDateRange,
      })
    );
    dispatch(applyFilter());
  };

  const additionalFieldsCount = useMemo(() => {
    return Object.keys(appliedFilters).reduce((count, key) => {
      return reportDataInfo.filterAdditional.includes(key) ? count + 1 : count;
    }, 0);
  }, [reportDataInfo.filterAdditional, appliedFilters]);

  const reportViews = useMemo(() => {
    return reportDataInfo.view.map((view, index) => view.viewName);
  }, [reportDataInfo]);

  useEffect(() => {
    const viewName = getQueryParam(location, "view");
    if (!viewName) {
      if (reportDataInfo.view.length === 0) return;

      const firstView = reportDataInfo.view[0];
      setReportView(firstView.viewName);
    } else {
      setReportView(viewName);
    }
  }, [location, reportDataInfo.view]);

  return (
    <div className="flex w-full flex-col gap-[17px] z-30">
      <div
        className={classNames("flex w-full gap-2", {
          "justify-between": selectedReport?.reportType === "custom",
          "justify-start": selectedReport?.reportType !== "custom",
        })}
      >
        <div className="flex gap-[7px] items-center">
          {selectedReport?.reportType === "custom" && (
            <DropDown
              options={reportViews}
              selectedOption={reportView}
              onOptionSelect={handleReportViewChange}
              label="Select View"
              dropdownBg="bg-custom-dropdownBg"
              dropdownText="text-custom-dropdownText"
            />
          )}

          <IconButton
            onClick={openVideo}
            size={24}
            padding={0}
            className="hover:bg-transparent"
          >
            <VideoTutorialIcon className="active:text-primary text-primaryText transition-colors" />
          </IconButton>
        </div>

        {selectedReport?.reportType === "custom" ? (
          <div className="flex gap-[11px]">
            <Button
              bgColor="bg-secondary"
              activeColor="active:bg-secondary-400"
              className="flex !h-[28px] px-[10px] py-[7px] items-center gap-[10px] rounded-[8px] "
              onClick={() => {
                handleShiftDate(DateDirection.Last);
              }}
            >
              <ArrowLeftIcon className="text-custom-buttonText" />

              <span className="text-custom-buttonText font-primary text-xs not-italic font-normal leading-[120%]">
                Prev
              </span>
            </Button>

            <Button
              bgColor="bg-secondary"
              activeColor="active:bg-secondary-400"
              className="flex !h-[28px] px-[10px] py-[7px] items-center gap-[10px] rounded-[8px] "
              onClick={() => {
                handleShiftDate(DateDirection.Next);
              }}
            >
              <span className="text-custom-buttonText font-primary text-xs not-italic font-normal leading-[120%]">
                Next
              </span>
              <ArrowRightIcon className="text-custom-buttonText" />
            </Button>
          </div>
        ) : (
          <div className="flex flex-end">
            <IconButton
              onClick={openTableauFilter}
              size={24}
              padding={0}
              className="hover:bg-transparent"
            >
              <FilterIcon className="active:text-primary text-primaryText transition-colors" />
            </IconButton>
          </div>
        )}
      </div>

      {selectedReport?.reportType === "custom" && (
        <div className="flex w-full gap-[10px]">
          <div className="flex gap-[13px] items-center w-[100%]">
            <IconButton
              onClick={() => {
                openReportView();
              }}
              size={24}
              padding={0}
              className="hover:bg-transparent relative"
            >
              <EyeIcon className="active:text-primary transition-colors text-primaryText" />

              <div className="absolute right-[0px] bottom-[-4px] w-[12px] h-[12px] bg-primary flex items-center justify-center rounded-xl">
                <span className="text-base-highlight text-center font-primary text-[8px] not-italic font-medium leading-[146%]">
                  {reportFilterViews.length}
                </span>
              </div>
            </IconButton>

            <IconButton
              onClick={() => {
                openReportFilter(ReportFilterCategory.Additional);
              }}
              size={24}
              padding={0}
              className="hover:bg-transparent relative"
            >
              <FilterIcon className="active:text-primary transition-colors text-primaryText" />

              <div className="absolute right-[0px] bottom-[-4px] w-[12px] h-[12px] bg-primary flex items-center justify-center rounded-xl">
                <span className="text-base-highlight text-center font-primary text-[8px] not-italic font-medium leading-[146%]">
                  {additionalFieldsCount}
                </span>
              </div>
            </IconButton>

            <Button
              bgColor="bg-custom-darkTeal"
              activeColor="active:bg-custom-deepCharcoal"
              className="w-[100%] sm:w-[100%] md:w-[200px] !h-[38px] border-[0.4px] border-solid border-custom-white-30 "
              onClick={() => {
                openReportFilter(ReportFilterCategory.Hierarchy);
              }}
            >
              <span className="text-primaryText font-primary text-xs not-italic font-semibold leading-[120%] whitespace-nowrap max-w-[160px] overflow-hidden overflow-ellipsis">
                {getFilterSummary(appliedFilters, reportDataInfo)}
              </span>
            </Button>
          </div>

          <SelectThemeButton openSelectTheme={openSelectTheme} />
        </div>
      )}
    </div>
  );
};
export default ReportControls;
