import { IProfileRankData } from "../../interfaces/profileRankBoard";

export const mapProfileRankBoard = (data: any): IProfileRankData => ({
  ...data,
  cleanSales: data.clean_sales,
  cleanSalesRank: data.clean_sales_rank,
  entityValue: data.entity_value,
  entityValueParent1: data.entity_value_parent_1,
  installs: data.installs,
  installsRank: data.installs_rank,
  photo: data.photo,
  sales: data.sales,
  salesRank: data.sales_rank,
  selectedEntityLevel: data.selected_entity_level,
});

export const mapProfile = (data: any): any => ({
  name: data.name,
  role: data.role,
  photo: data.photo,
  salesRank: data.sales_rank,
  installsRank: data.installs_rank,
  cleanSalesRank: data.clean_sales_rank,
});

export const parseStats = (data: any, searchCategory: any) => {
  const stats = data
    .map((item: any) => {
      const newItem: any = {
        category: item.selected_entity_level,
        name: item.entity_value,
        fields: {},
      };

      Object.keys(item).forEach((key) => {
        if (
          [
            "selected_entity_level",
            "entity_value_parent_1",
            "entity_value",
          ].includes(key)
        ) {
          return;
        }

        if (key.includes("rank")) {
          newItem.fields[key.replace("_rank", "")].rank = item[key];
        } else {
          newItem.fields[key] = {
            label: key
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            value: item[key],
            rank: "",
          };
        }
      });

      return newItem;
    })
    .reduce((mapping: any, item: any) => {
      if (item.category === searchCategory) {
        mapping[item.category] = item;
        return mapping;
      }

      if (!mapping[item.category]) {
        mapping[item.category] = [];
      }
      mapping[item.category].push(item);
      return mapping;
    }, {} as any);
  return stats;
};
