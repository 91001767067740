import { Link } from "react-router-dom";
import { IReportList } from "../../interfaces/reportList";
import DropDownArrowIcon from "../icons/DropDownArrowIcon";

interface ReportListsProps {
  open?: boolean;
  reports: IReportList[];
  closeMenu: () => void;
}

const ReportLists: React.FC<ReportListsProps> = ({
  open = false,
  closeMenu,
  reports,
}) => {
  return (
    <div className="grid divide-y divide-custom-grey-fa">
      {reports.map((report, index) => {
        return (
          <details className="group" key={index} open={open}>
            <summary className="group-open:bg-base group-open:border-b-[0.4px] group-open:border-solid group-open:border-b-custom-grey-a3 transition duration-300 outline-none px-[12px] py-m  flex justify-between items-center font-medium cursor-pointer list-none">
              <span className="text-opposite-highlight font-primary text-lg not-italic font-semibold leading-[120%] select-none">
                {report.categoryName}
              </span>
              <span className="transition group-open:rotate-180">
                <DropDownArrowIcon className="text-opposite-highlight" />
              </span>
            </summary>

            <div className="flex flex-col bg-base group-open:animate-fadeIn">
              {report.reports.map((report, index) => {
                return (
                  <Link
                    target={
                      report.reportType === "web_link" ? "_blank" : "_self"
                    }
                    to={
                      report.reportType === "web_link"
                        ? report.reportUrl
                        : `/report?alias=${report.alias}`
                    }
                    className="px-[24px] py-[8px] text-opposite-highlight hover:bg-neutral-200 transition font-primary not-italic font-semibold leading-[120%] cursor-pointer"
                    key={index}
                    onClick={closeMenu}
                  >
                    {report.reportName}
                  </Link>
                );
              })}
            </div>
          </details>
        );
      })}

      <Link target="_self" to="/profile" onClick={closeMenu}>
        <div className="group">
          <div className="group-open:bg-base group-open:border-b-[0.4px] group-open:border-solid group-open:border-b-custom-grey-a3 transition duration-300 outline-none px-[12px] py-m  flex justify-between items-center font-medium cursor-pointer list-none">
            <span className="text-opposite-highlight font-primary text-lg not-italic font-semibold leading-[120%] select-none">
              Profiles
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ReportLists;
