import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { IHomeCard, IHomeSheet } from "../../interfaces/homeSheet";
import { IHomeCompetitionCard } from "../../interfaces/homeCompetitionCard";
import { SheetCategory } from "../../const/enums/HomeCompetitionCardType";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";

import trashIcon from "../../assets/icons/trash-01.svg";
import CloseIcon from "../icons/CloseIcon";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  deleteHomeCompetitionCardsAction,
  deleteHomeProfileCardsAction,
  deleteHomeSheetAction,
  updateHomeCardPositions,
} from "../../redux/slices/reportSlice";
import { IHomeProfileCard } from "../../interfaces/homeProfileCard";

interface EditHomeCardsPopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

interface HomeSheetItemProps {
  sheet: IHomeSheet;
  isLast: boolean;
  onRemoveHomeSheet: () => void;
}

const HomeSheetItem = ({
  sheet,
  isLast,
  onRemoveHomeSheet,
}: HomeSheetItemProps) => {
  return (
    <div
      className={`flex px-[8px] py-2 gap-[12px] items-center w-full ${
        !isLast && "border-b-[1px] border-solid border-neutral-900"
      }`}
    >
      <h2 className="flex-grow-[1] text-base-highlight font-primary not-italic font-semibold leading-[120%] select-none whitespace-nowrap overflow-hidden overflow-ellipsis">
        {sheet.reportName} - {sheet.reportViewName} -{" "}
        {sheet.reportFilterViewName}
      </h2>

      <IconButton
        icon={trashIcon}
        size={16}
        onClick={onRemoveHomeSheet}
      ></IconButton>
    </div>
  );
};

interface HomeCompetitionCardsItemProps {
  card: IHomeCompetitionCard;
  isLast: boolean;
  onRemoveHomeCompetitionCard: () => void;
}

const HomeCompetitionCardsItem = ({
  card,
  isLast,
  onRemoveHomeCompetitionCard,
}: HomeCompetitionCardsItemProps) => {
  return (
    <div
      className={`flex px-[8px] py-2 gap-[12px] items-center w-full ${
        !isLast && "border-b-[1px] border-solid border-neutral-900"
      }`}
    >
      <h2 className="flex-grow-[1] text-base-highlight font-primary not-italic font-semibold leading-[120%] select-none whitespace-nowrap overflow-hidden overflow-ellipsis">
        {card.cardName}
      </h2>

      <IconButton
        icon={trashIcon}
        size={16}
        onClick={onRemoveHomeCompetitionCard}
      ></IconButton>
    </div>
  );
};

interface HomeProfileCardItemProps {
  card: IHomeProfileCard;
  isLast: boolean;
  onRemoveHomeProfileCard: () => void;
}

const HomeProfileCardItem = ({
  card,
  isLast,
  onRemoveHomeProfileCard,
}: HomeProfileCardItemProps) => {
  return (
    <div
      className={`flex px-[8px] py-2 gap-[12px] items-center w-full ${
        !isLast && "border-b-[1px] border-solid border-neutral-900"
      }`}
    >
      <h2 className="flex-grow-[1] text-base-highlight font-primary not-italic font-semibold leading-[120%] select-none whitespace-nowrap overflow-hidden overflow-ellipsis">
        {card.cardName}
      </h2>

      <IconButton
        icon={trashIcon}
        size={16}
        onClick={onRemoveHomeProfileCard}
      ></IconButton>
    </div>
  );
};

const EditHomeCardsPopup = ({
  isPopupOpen,
  closePopup,
}: EditHomeCardsPopupProps) => {
  const dispatch = useAppDispatch();
  const { homeCards } = useAppSelector((state) => state.report);
  const [tempCards, setTempCards] = useState<IHomeCard[]>([]);

  useEffect(() => {
    setTempCards(homeCards);
  }, [homeCards]);

  const onSaveChange = () => {
    toast.promise(
      dispatch(
        updateHomeCardPositions(
          tempCards.map((card, i) => ({
            ...card,
            position: i + 1,
          }))
        )
      ).unwrap(),
      {
        loading: "Saving home cards...",
        success: "Successfully saved home cards.",
        error:
          "Oops! Something went wrong while trying to save the home cards.",
      }
    );
    closePopup();
  };

  const onToggleRemoveCompetitionCard = (index: number) => {
    const card = tempCards[index];

    toast.promise(
      dispatch(
        deleteHomeCompetitionCardsAction({
          ...card,
        })
      ).unwrap(),
      {
        loading: "Removing from home screen...",
        success:
          "The card has been successfully removed from your home screen.",
        error:
          "Oops! There was an error while trying to remove the card from your home screen.",
      }
    );
  };

  const onToggleRemoveProfileCard = (index: number) => {
    const card = tempCards[index];

    toast.promise(
      dispatch(
        deleteHomeProfileCardsAction({
          ...card,
        })
      ).unwrap(),
      {
        loading: "Removing from home screen...",
        success:
          "The card has been successfully removed from your home screen.",
        error:
          "Oops! There was an error while trying to remove the card from your home screen.",
      }
    );
  };

  const onToggleRemoveSheet = (index: number) => {
    const card = tempCards[index];

    toast.promise(
      dispatch(
        deleteHomeSheetAction({
          reportId: card.reportId,
          reportFilterViewName: card.reportFilterViewName,
          reportViewName: card.reportViewName,
          sheetType: card.sheetType,
          sheetField: card.sheetField || "",
        })
      ).unwrap(),
      {
        loading: "Removing from home screen...",
        success:
          "The sheet has been successfully removed from your home screen.",
        error:
          "Oops! There was an error while trying to remove the sheet from your home screen.",
      }
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newCards = [...tempCards];
    const [removed] = newCards.splice(result.source.index, 1);
    newCards.splice(result.destination.index, 0, removed);
    setTempCards(newCards);
  };

  return (
    <div>
      <MobilePopup
        backBg="bg-overlay"
        popupBg="bg-custom-deepCharcoal"
        popupBorder="ring-secondary-900"
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <div className="flex flex-col gap-m">
          <div className="flex items-center justify-between py-[16px] px-[19px] ">
            <div>
              <h2 className="text-textPrimary font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Edit Home Cards
              </h2>
            </div>

            <IconButton onClick={closePopup}>
              <CloseIcon className="text-primaryText" />
            </IconButton>

            <div className="w-[1px] h-[21px] mt-[12px] bg-neutral-900 fixed left-[50%] top-0"></div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="tempCards">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col gap-[8px] px-[12px] min-h-[320px] max-h-[70vh] overflow-y-auto relative"
                >
                  {tempCards.map((card, index) => {
                    return (
                      <Draggable
                        key={card.sheetCategory + card.id}
                        draggableId={`${card.sheetCategory + card.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              ...("position" in
                              (provided.draggableProps.style ?? {})
                                ? {
                                    position: "absolute",
                                    top:
                                      40 * index +
                                      8 * (index > 0 ? index - 1 : 0) +
                                      "px",
                                    left: "8px",
                                  }
                                : {}),
                            }}
                          >
                            {card.sheetCategory === SheetCategory.Report && (
                              <HomeSheetItem
                                sheet={card}
                                isLast
                                onRemoveHomeSheet={() =>
                                  onToggleRemoveSheet(index)
                                }
                              />
                            )}
                            {card.sheetCategory ===
                              SheetCategory.Competition && (
                              <HomeCompetitionCardsItem
                                card={card}
                                isLast
                                onRemoveHomeCompetitionCard={() =>
                                  onToggleRemoveCompetitionCard(index)
                                }
                              />
                            )}
                            {card.sheetCategory === SheetCategory.Profile && (
                              <HomeProfileCardItem
                                card={card}
                                isLast
                                onRemoveHomeProfileCard={() =>
                                  onToggleRemoveProfileCard(index)
                                }
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="flex justify-center items-center px-[12px] py-[16px] gap-[16px] border-t-[1px] border-solid border-t-neutral-800">
            <Button
              bgColor="bg-base-highlight"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
              onClick={closePopup}
            >
              <span className="font-primary text-opposite-highlight text-sm not-italic font-semibold leading-[120%]">
                Cancel
              </span>
            </Button>

            <Button
              activeColor="active:bg-primary-400"
              className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
              onClick={onSaveChange}
            >
              <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
                Save Changes
              </span>
            </Button>
          </div>
        </div>
      </MobilePopup>
    </div>
  );
};

export default EditHomeCardsPopup;
