import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { L_STORAGE_THEME } from "../../const/localstorage";
import { ITheme } from "../../interfaces/theme";
import {
  deleteTheme,
  editTheme,
  getThemes,
  saveTheme,
} from "../../services/userService";
import { parseThemes } from "../helpers/global";

export interface GlobalState {
  previousPage: PageType;
  currentPage: PageType;
  currentReportFilterCategory: ReportFilterCategory;
  isReportFilterOpened: boolean;
  isReportViewOpened: boolean;
  isMenuOpened: boolean;
  isPopupOpened: boolean;
  isFullScreen: boolean;

  showLoadingOverlay: boolean;

  themes: ITheme[];
  currentTheme: string;
}

const initialState: GlobalState = {
  previousPage: PageType.Home,
  currentPage: PageType.Home,
  currentReportFilterCategory: ReportFilterCategory.Date,
  isReportFilterOpened: false,
  isReportViewOpened: false,
  isMenuOpened: false,
  isPopupOpened: false,
  isFullScreen: false,

  showLoadingOverlay: false,

  themes: [],
  currentTheme: "Default",
};

export const fetchThemesAction = createAsyncThunk(
  "global/fetchThemesAction",
  async () => {
    const response = await getThemes();

    return response;
  }
);

export const saveThemeAction = createAsyncThunk(
  "global/saveThemeAction",
  async ({
    themeColor,
    themeName,
  }: {
    themeName: string;
    themeColor: string;
  }) => {
    const response = await saveTheme({ themeName, themeColor });

    return response;
  }
);

export const deleteThemeAction = createAsyncThunk(
  "global/deleteThemeAction",
  async ({ themeId }: { themeId: number }) => {
    const response = await deleteTheme(themeId);

    return response;
  }
);

export const editThemeAction = createAsyncThunk(
  "global/editThemeAction",
  async ({
    themeId,
    themeName,
    themeColors,
  }: {
    themeId: number;
    themeName: string;
    themeColors: string;
  }) => {
    const response = await editTheme({
      themeId,
      themeName,
      themeColors,
    });

    return response;
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setCurrentReportFilterCategory: (state, { payload }) => {
      state.currentReportFilterCategory = payload;
    },
    setMenuOpened: (state, { payload }) => {
      state.isMenuOpened = payload;
      state.isPopupOpened = payload;
    },
    setReportFilterOpened: (state, { payload }) => {
      state.isReportFilterOpened = payload;
      state.isPopupOpened = payload;
    },

    setReportViewOpened: (state, { payload }) => {
      state.isReportViewOpened = payload;
      state.isPopupOpened = payload;
    },

    setPopupOpened: (state, { payload }) => {
      state.isPopupOpened = payload;
    },

    setCurrentPage: (state, { payload }) => {
      state.previousPage = state.currentPage;
      state.currentPage = payload;
    },

    setShowLoadingOverlay: (state, { payload }) => {
      state.showLoadingOverlay = payload;
    },

    setFullScreen: (state, { payload }) => {
      state.isFullScreen = payload;
    },

    loadTheme: (state) => {
      const savedTheme = localStorage.getItem(L_STORAGE_THEME) || "Default";

      state.currentTheme = savedTheme;
    },

    updateCurrentTheme: (state, { payload }) => {
      state.currentTheme = payload;
      localStorage.setItem(L_STORAGE_THEME, payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemesAction.pending, (state) => {});
    builder.addCase(fetchThemesAction.fulfilled, (state, { payload }) => {
      state.themes = parseThemes(payload);
    });
    builder.addCase(fetchThemesAction.rejected, (state, { error }) => {});

    builder.addCase(saveThemeAction.pending, (state) => {});
    builder.addCase(saveThemeAction.fulfilled, (state, { payload }) => {
      state.themes = parseThemes(payload);
    });
    builder.addCase(saveThemeAction.rejected, (state, { error }) => {});

    builder.addCase(deleteThemeAction.pending, (state) => {});
    builder.addCase(deleteThemeAction.fulfilled, (state, { payload }) => {
      state.themes = parseThemes(payload);
    });
    builder.addCase(deleteThemeAction.rejected, (state, { error }) => {});

    builder.addCase(editThemeAction.pending, (state) => {});
    builder.addCase(editThemeAction.fulfilled, (state, { payload }) => {
      state.themes = parseThemes(payload);
    });
    builder.addCase(editThemeAction.rejected, (state, { error }) => {});
  },
});

export const {
  loadTheme,
  setPopupOpened,
  setCurrentReportFilterCategory,
  setMenuOpened,
  setReportViewOpened,
  setReportFilterOpened,
  setCurrentPage,
  setShowLoadingOverlay,
  setFullScreen,
  updateCurrentTheme,
} = globalSlice.actions;

export default globalSlice.reducer;
