// redux/store.ts
import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import globalReducer from "./slices/globalSlice";
import reportReducer from "./slices/reportSlice";
import viperReducer from "./slices/viperSlice";
import cupReducer from "./slices/cupSlice";
import profileReducer from "./slices/profileSlice";

const store = configureStore({
  reducer: {
    report: reportReducer,
    global: globalReducer,
    auth: authReducer,
    viper: viperReducer,
    cup: cupReducer,
    profile: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
