import axiosInstance from "./axiosInstance";

export const searchProfile = async (category: string, search: string) => {
  try {
    const response = await axiosInstance.get("/profile/search", {
      params: { category, search },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profiles.", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

export const fetchProfile = async (profileQuery: any) => {
  try {
    const response = await axiosInstance.get("/profile", {
      params: profileQuery,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profile.", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

export const fetchRankBoard = async (rankBoardQuery: any) => {
  try {
    const response = await axiosInstance.get("/profile/rank_board", {
      params: rankBoardQuery,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching rank board.", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

export const fetchStats = async (statsQuery: any) => {
  try {
    const response = await axiosInstance.get("/profile/stats", {
      params: statsQuery,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching stats.", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};
