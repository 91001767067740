import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "../../components/Common/Button";
import IconToggle from "../Common/IconToggle";

import { FavoriteType } from "../../const/enums/FavoriteType";
import { IProfile, ProfileCategory } from "../../interfaces/profile";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleFavorite } from "../../redux/slices/reportSlice";

import StarIcon from "../icons/StarIcon";
import StarIconEmpty from "../icons/StarIconEmpty";

interface Props {
  profile: IProfile;
  category: string;
}

export default function MainProfile({ profile, category }: Props) {
  const dispatch = useAppDispatch();

  const { favorites } = useAppSelector((state) => state.report);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);

  const handleFollow = () => {
    let favoriteType = FavoriteType.Profile;
    let favoriteId = 1;
    let favoriteName = `${category} - ${profile.name}`;
    let favoriteImageLink = profile.photo ?? "";

    toast.promise(
      dispatch(
        toggleFavorite({
          favoriteType,
          favoriteId,
          favoriteName,
          favoriteImageLink,
        })
      ).unwrap(),
      {
        loading: "Following...",
        success: "Successfully followed the profile",
        error: "Oops! Something went wrong while trying to follow the profile.",
      }
    );
  };

  useEffect(() => {
    const checkFavorite = (type: FavoriteType, profileName: string) =>
      favorites.some(
        (favorite) =>
          favorite.favoriteType === type &&
          favorite.favoriteId === 1 &&
          favorite.favoriteName === `${category} - ${profile.name}`
      );
    console.log(checkFavorite(FavoriteType.Profile, profile.name));
    setIsFollowing(checkFavorite(FavoriteType.Profile, profile.name));
  }, [category, favorites, profile.name]);

  return (
    <div>
      <div
        style={{
          background: "var(--bg-profile-gradient)",
        }}
      >
        <div className="flex gap-2">
          <ProfileImage
            src={
              profile.photo?.startsWith("http")
                ? profile.photo
                : category === ProfileCategory.Rep
                ? "/assets/imgs/user_square.png"
                : "/assets/imgs/users_square.png"
            }
          />

          <div className="py-4">
            {profile.role && (
              <div className="uppercase text-custom-white-90 text-xs">
                {profile.role}
              </div>
            )}
            <div className="text-primaryText font-semibold text-lg py-1">
              {profile.name}
            </div>
            {/* <div className="text-custom-white-70 text-sm">Little Rock</div> */}
            <div className="mt-3">
              <Button
                bgColor="bg-secondary"
                className="flex !h-[28px] px-[10px] py-[7px] items-center gap-[10px] rounded-[8px] text-black"
                onClick={handleFollow}
              >
                <IconToggle
                  value={isFollowing}
                  onValueChange={() => {}}
                  iconOn={<StarIcon className="size-4" />}
                  iconOff={<StarIconEmpty className="size-4 " />}
                ></IconToggle>

                <span className="text-custom-buttonText font-primary text-xs not-italic font-semibold leading-[120%]">
                  {isFollowing ? "Unfollow" : "Follow"}
                </span>
              </Button>
            </div>
          </div>
        </div>

        <div className="p-3 flex justify-around text-sm border-b border-[#0B0A0A]">
          <div className="flex items-center gap-2">
            <PhoneIcon className="size-4" />
            <span>###-###-####</span>
          </div>
          <div className="flex items-center gap-2">
            <EnvelopeIcon className="size-4" />
            <span>####@gmail.com</span>
          </div>
        </div>

        <div className="p-3 flex justify-around text-sm border-b border-[#0B0A0A] text-center">
          <div>
            <div className="uppercase text-neutral-200">SENIOR MGMT</div>
            <div>Tristan Pears SR MGMT</div>
          </div>
          <div>
            <div className="uppercase text-neutral-200">MGMT Group</div>
            <div>Gates Pears MGMT</div>
          </div>
        </div>
      </div>

      <div className="p-3 flex w-full justify-between text-sm border-b border-[#0B0A0A] text-center">
        <div className="border-t border-black grid grid-cols-3 w-full">
          <div className="text-center py-3">
            <div className="text-custom-white-80 text-xs">SALES</div>
            <div className="text-white mt-1 text-2xl font-bold">
              {profile.salesRank ?? 0}
            </div>
          </div>
          <div className="text-center py-3 border-x border-black">
            <div className="text-custom-white-80 text-xs">INSTALLS</div>
            <div className="text-white mt-1 text-2xl font-bold">
              {profile.installsRank ?? 0}
            </div>
          </div>
          <div className="text-center py-3">
            <div className="text-custom-white-80 text-xs">CLEAN SALES</div>
            <div className="text-white mt-1 text-2xl font-bold">
              {profile.cleanSalesRank ?? 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ProfileImage = ({ src }: { src: string }) => (
  <svg
    width="140"
    height="140"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id="clipPolygon">
        <path d="M 0 100 L 0 0 L 100 0 C 92 26 89 46 77 68 C 66 83 56 88 43 93 C 28 97 10 100 0 100 Z" />
      </clipPath>

      <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#F8F8F8" />
        <stop offset="100%" stopColor="#CEC39E" />
      </linearGradient>
    </defs>

    <image
      href={src}
      x="0"
      y="0"
      width="100"
      height="100"
      clipPath="url(#clipPolygon)"
      preserveAspectRatio="xMidYMid slice"
    />

    <path
      d="M 100 0 C 92 26 89 46 77 68 C 66 83 56 88 43 93 C 28 97 10 100 0 100"
      fill="none"
      stroke="url(#gradientStroke)"
      strokeWidth="2"
    />
  </svg>
);
